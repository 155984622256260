export default {
    // "arena": 0,
    "name": undefined,
    "hero": {
        "id": "1pQ3y4Mlbff53w9JUImx",
        "level": 1
    },
    "units": [
        {
            "id": 'Jc9Kugc2HGYL1lF5RRmZ',
            "level": 1
        },
        {
            "id": '9AuYNvoAxqsJqZ0Uhope',
            "level": 1
        },
        {
            "id": '0dOJkz7p6gxtSpXombTK',
            "level": 1
        },
        {
            "id": 'UL9zqW9rTcc3e3QOL4Kr',
            "level": 1
        },
        {
            "id": 'iTPb3ST5RMasxiXbiUHF',
            "level": 1
        },
    ],
    "min_win": 0,
    "max_win": 0,
    "status": 0
}